import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Quote } from "../components/Quote";
import { Row, Column } from "../style";

export default function HomePage() {
  const data = useStaticQuery(
    graphql`
      query {
        HTA_2018_FinalistLogo_Horizontal: file(
          relativePath: {
            eq: "Takiwa-Data-Analytics-Platform-HTA-2018-Finalist.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        HTA_2019_FinalistLogo_Horizontal: file(
          relativePath: {
            eq: "Takiwa-Data-Analytics-Platform-HTA-2019-Finalist.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        new_zealand_excellence_in_it_awards: file(
          relativePath: {
            eq: "Takiwa-Data-Analytics-Platform-New-Zealand-Excellence-In-IT-Award.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        Selection_217: file(
          relativePath: { eq: "Takiwa-Mapping-and-Data-Analytics-Platform.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Page
        heroHeadline="GET THE MOST OUT OF YOUR DATA"
        heroTextline="Award winning data management and analytics platform"
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_platform.jpg"
        }
      >
        <Title>
          Create data sets, maps, analysis, and reports within minutes
        </Title>
        <Quote>
          Takiwā Data Analytics Platform is a cloud-based web application for
          providing SaaS products to a range of customers. The products are
          delivered as packages of particular features, enabling users with
          tools for manipulation and analysis of heterogeneous data that usually
          include geographic spatial and temporal components. Takiwā products
          combine geographic information system (GIS), business intelligence
          (BI) platform, and enterprise management system (EMS) functionality,
          and potentially this functionality may be extended even further in
          order to provide the best user experience in the process of data
          manipulation, mining and analysis.
        </Quote>

        <Row>
          <Column span="12">
            Takiwā Data Analytics Platform offers a single solution for all
            needs in business analysis of data including location and time
            components. <br />
            It lowers the barrier for users enabling them to utilize the full
            power of locational business intelligence systems for a fraction of
            cost.
          </Column>
        </Row>

        <Quote>Complex data easily</Quote>

        <Row>
          <Column span="6">
            <p>
              Takiwā Vision is the next spin in the evolution of tools in
              spatial analysis. It combines ideas of low-code modularity with
              service integration and social engagement. Its mission is to give
              the users and third-party developers the ability to integrate into
              Takiwā core services using a set of pre-defined application
              templates and intelligent APIs. The objective of Takiwā Vision is
              to solve customers’ problems in a fast and efficient manner and
              encourage cooperation and reusability of solutions.
            </p>
          </Column>
          <Column span="6">
            <Img fluid={data.Selection_217.childImageSharp.fluid} />
          </Column>
        </Row>

        <Quote>The power of Takiwā</Quote>

        <Row>
          <Column span="6">
            <p>
              We work with experts in our target sectors to help empower
              organisations and communities to build their own capability and
              capacity. Our vision is scientists, policy makers, community
              leaders and stakeholders having the ability to see the same
              picture and agree a way forward where everyone can contribute.
            </p>
            <p>
              A scalable, multi-tenanted spatial analytics engine, translating
              big complex data-sets into simple, understandable visualisations
              and business intelligence. Takiwā applications are currently
              collecting data from an incredibly broad range of sources, from
              in-lake sensors to large Government data sets. Backed by an
              organisation committed to investing in ongoing research and
              development.
            </p>
            <p>
              Takiwā delivers deeper insights faster by presenting complex
              information in simple ways, providing users with spatial, temporal
              and analytic views of data. It also provides the ability to
              monitor progress in real-time and report on the fly.
            </p>
          </Column>
          <Column span="6">
            <p>
              Takiwā Data Analytics Platform have ability to distinguish between
              the ordinary tenants and white-labelled tenants, where the latter
              have the ability to re-brand the product and use custom domain
              names.
            </p>
            <p>
              Quickly create datasets by uploading CSV files, Shape files,
              GeoJson files or simply draw on the map.
            </p>
            <p>
              Takiwā Data Analytics Platform is designed for taking your raw
              data and converting it into information useful for
              decision-making. Explore your data using smart Takiwā tools for a
              deeper data analysis.
            </p>
            <p>
              Generate sophisticated Reports within minutes. Combine Analysis
              results made in other sections of the Takiwā Data Analytics
              Platform.
            </p>
          </Column>
        </Row>
        <Row>
          <p>
            The data processed through Takiwā applications is often sensitive
            and valuable, so we put a premium on the security and robustness of
            our platforms. Partnering with Cloud has enabled us to achieve a
            gold standard in this area, acceptable to risk averse organisations
            with tight requirements around data protection and data sovereignty.
          </p>
        </Row>

        <Row>
          <Column span="4">
            <Quote light>
              <p>2018 WINNER EXCELLENCE IN GOVTECH</p>
              <Img
                fluid={
                  data.new_zealand_excellence_in_it_awards.childImageSharp.fluid
                }
              />
            </Quote>
          </Column>
          <Column span="4">
            <Quote light>
              <p>2018 FINALIST FOR NZ HI-TECH AWARDS</p>
              <br />
              <Img
                fluid={
                  data.HTA_2018_FinalistLogo_Horizontal.childImageSharp.fluid
                }
              />
            </Quote>
          </Column>
          <Column span="4">
            <Quote light>
              <p>2019 FINALIST FOR NZ HI-TECH AWARDS</p>
              <br />
              <Img
                fluid={
                  data.HTA_2019_FinalistLogo_Horizontal.childImageSharp.fluid
                }
              />
            </Quote>
          </Column>
        </Row>
      </Page>
    </Layout>
  );
}
